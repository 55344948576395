export default theme => ({ 
	root: { 
		paddingTop: theme.spacing(.5), 
		paddingBottom: theme.spacing(.5), 
		paddingLeft: theme.spacing(1), 
		paddingRight: theme.spacing(1),
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
			textAlign: 'center'
		}
	} 
}) 